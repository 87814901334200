.row-data {
  border: 0.5px solid #999;
  padding: 10px;
}

.question-description {
  /* border: 0.5px solid #999; */
  padding: 7px;
  /* margin: 20px; */
}

textarea:focus {
  outline: none !important;
  border: 0;
}

.question-background {
  /* background-image: url("https://api.epsexam.com/wp-content/uploads/2024/03/12-2.jpeg"); */
  background-repeat: no-repeat;
  background-position: center;

}

/* .home-box {
  background-image: linear-gradient(80deg, #202020, #3d3d3d);

} */

@media screen and (min-width: 600px) {
  .Home-Carosel-Desktop-Style {
    display: block;
  }

  .image-home-block {
    height: 100px;
  }

  .image-home-block-2 {
    height: 250px;
  }

  .home-block-text {
    font-size: 14px;
    font-weight: 500;
  }

  .Home-Carosel-Mobile-Style {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .Home-Carosel-Desktop-Style {
    display: none;
  }

  .image-home-block {
    height: 60px;
  }

  .image-home-block-2 {
    height: 150px;
  }

  .home-block-text {
    font-size: 12px;
    font-weight: 500;
  }

  .Home-Carosel-Mobile-Style {
    display: block;
  }
}



@media screen and (min-width: 1000px) {
  .question-list {
    text-align: right;
  }

  .row-data-result {
    border: 0.5px solid #999;
    padding: 10px;
  }

  .row-data-result-phone {
    display: none;
  }



  .que-title-view {
    font-size: 20px;
    font-weight: bold;
  }

  .que-title-view-2 {
    font-size: 20px;
    font-weight: bold;
    width: 20%;
    /* padding: 10px; */
  }

  .sign-in-button-11 {
    cursor: pointer;
    background-color: #003068;
    margin: 10px 0px 0;
    padding: 7px 30px;
    border: 1.5px solid #003068;
    /* min-width: 150px; */
    color: #fff;
    font-weight: bold;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    text-align: center;
  }

  .sign-in-button-11:hover {
    transform: scale(1.1);
  }

  .question-solved {
    font-size: 20px;
  }

  .question-font {
    font-size: 28px;
  }

  .question-block {
    margin-top: 20px;
  }

  .question-image {
    width: 100%;
    height: 350px;
    object-fit: contain;
    border: 0.5px solid #000;
    border-radius: 20px;
  }

  .answer-text {
    font-size: 30px;
  }

  .que-title-view {
    font-size: 20px;
    padding: 10px;
    font-weight: bold;
  }

  .que-title-view-2 {
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
    width: 20%;
  }

  .home-icons {
    width: 20vh;
    height: 20vh;

  }

  .home-fonts {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #000;
  }

  .title-padding {
    padding: 15px;
  }

  .question-number-font {
    font-size: 20px;
    padding: 20px;
  }

  .question-text {
    font-size: 30px;
  }

  .question-description-text {
    font-size: 25px;
  }

  .question-media-text {
    font-size: 27px;
    text-align: center;
  }

  .answer-text {
    font-size: 27px;
  }

}



@media screen and (max-width: 720px) {

  .que-title-view-2 {
    font-size: 12px;
    font-weight: bold;
  }

  .image-zoom-modal {
    width: 90vh;
    height: 90vw;
  }

}

@media screen and (max-width: 1000px)and(min-height:720px) {
  .question-height {
    height: 70vh;
  }
}

@media screen and (max-width: 720px) {
  .question-height {
    height: 74vw;
  }
}

@media screen and (min-width: 1000px) {
  .question-height {
    height: 60vh;
  }
}

@media screen and (max-width: 1000px) {

  .question-text {
    font-size: 18px;
  }

  .question-description-text {
    font-size: 16px;
  }

  .question-media-text {
    font-size: 18px;
    text-align: center;
  }


  .answer-text {
    font-size: 18px;
  }

  .question-number-font {
    font-size: 16px;
    /* padding: 5px; */
  }

  .que-title-view-2 {
    font-size: 12px;
    font-weight: bold;
  }

  .title-padding {
    padding: 5px;
  }


  .home-fonts {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #000;
  }

  .home-icons {
    width: 20vw;
    height: 20vw;
  }

  .answer-text {
    font-size: 17px;
  }

  .question-image {
    width: 100%;
    height: 250px;
    object-fit: contain;
    border: 0.5px solid #000;
  }

  .question-font {
    font-size: 18px;
  }


  .question-solved {
    font-size: 10px;
  }

  .sign-in-button-11 {
    cursor: pointer;
    background-color: #003068;
    margin: 0px 0px 0;
    padding: 7px 30px;
    border: 1.5px solid #003068;
    /* min-width: 150px; */
    color: #fff;
    font-weight: bold;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    text-align: center;
  }

  .sign-in-button-11:hover {
    transform: scale(1.1);
  }

  .question-list {
    text-align: center;
  }



  .previous-question {
    text-align: center;
  }

  .row-data-result {
    display: none
  }

  .row-data-result-phone {
    border: 3px solid #000;
    padding: 3px 10px;
    margin: 5px 0;
  }

  .row-data-result-phone2 {
    border-bottom: 0.5px solid #999;
    padding: 10px;
  }
}

audio::-webkit-media-controls-timeline-container {
  display: none !important;
}

audio::-webkit-media-controls-current-time-display {
  display: none !important;
}

audio::-webkit-media-controls-time-remaining-display {
  display: none !important;
}

audio::-webkit-media-controls-timeline {
  display: none !important;
}

audio::-webkit-media-controls-volume-slider-container {
  display: none !important;
}

audio::-webkit-media-controls-volume-slider {
  display: none !important;
}

audio::-webkit-media-controls-seek-back-button {
  display: none !important;
}

audio::-webkit-media-controls-seek-forward-button {
  display: none !important;
}

audio::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

audio::-webkit-media-controls-rewind-button {
  display: none !important;
}

audio::-webkit-media-controls-return-to-realtime-button {
  display: none !important;
}

audio::-webkit-media-controls-toggle-closed-captions-button {
  display: none !important;
}


audio {
  width: 100px;
  height: 54px;
}

.active-bar {
  padding: 10px;
  background-color: #fff;
  text-align: center;
  border: 1px solid #000;
  font-weight: bold;
}

.passive-bar {
  padding: 10px;
  background-color: rgb(119, 118, 118);
  text-align: center;
  color: #fff;
  border: 1px solid #000;
  font-weight: bold;
  cursor: pointer;
}

/* .home-box {
  background-image: linear-gradient(80deg, #202020, #3d3d3d);

} */

.home-box:hover {
  transform: scale(1.04);


}

.button-play {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 20px;
  border-radius: 20px;
  border: 1px solid;
}

.image-modal {
  margin-top: 20vh;
}

.image-modal:hover {
  transform: scale(2);
  margin-top: 20vh;
}


.answer-option-style {
  padding: 3px 10px;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #999;
}

.wrong-option-result {
  font-weight: bold;
  padding: 0px 10px;
  background-color: #b21e1e;
  color: #fff;

}

.correct-option-result {
  font-weight: bold;
  background-color: #66fc03;
}

.wrong-option-result-2 {
  font-weight: bold;
  background-color: #b21e1e;
  color: #fff;
  text-align: center;
  padding: 10px 10px;
}

.correct-option-result-2 {
  font-weight: bold;
  padding: 10px 10px;
  background-color: #66fc03;
  text-align: center;
}

.active-option {
  border: 2px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  font-weight: bold;
  padding: 15px 10px;
  font-size: 12px;
  border-radius: 10px;
}

.passive-option {
  border: 0.5px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0);
  padding: 15px 10px;
  font-size: 12px;
  border-radius: 10px;
}


.font-size-12 {
  font-size: 12px;
}